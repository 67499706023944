import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import UnorderedList from "~/components/List/Unordered";
import Footnote from "~/components/footnote/footnote";

const Page = () => {
  return (
      <LessonLayout
        current_step={3}
        lesson={"Lesson 1"}
        color={"hs"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2>Step 3</H2>

          <div className="mt-5 w-2/3 flex content-end">
            <StaticImage
              alt="Hand using tablet."
              className="rounded"
              src="../../../images/lesson_1_step_3.jpg"
              loading="eager"
              placeholder="blurred"
              style={{
                filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
              }}
            />
          </div>

          <p>People can spend a lot of time looking at websites. Often, information on one website is different from information on another website. After looking at a site, have you ever felt like you wasted your time because you did not find what you were looking for? Have you spent a long time looking at a site before figuring out that the information is confusing or wrong? How did it affect you getting the information you wanted?</p>
          <p>People get a lot of information about their health from the internet. This information can be hard to make sense of. Understanding the information can be even harder if a website is of poor quality. According to the Centers for Disease Control and Prevention (CDC), 9 out of 10 adults have trouble understanding health information.<sup>1</sup> The problem is worse when</p>

          <UnorderedList>
            <li>the topic is new to them,</li>
            <li>the information is difficult, or</li>
            <li>there is a lot of jargon. (Jargon is special words or phrases used by specific professions that most people do not know.)</li>
          </UnorderedList>

          <p>If people do not understand the information, they may make poor decisions.
          </p>

          <Footnote
            author="Centers for Disease Control & Prevention. (2019)."
            footnoteNumber="1"
            href="https://www.cdc.gov/healthliteracy/shareinteract/TellOthers.html"
            source="Retrieved from"
            title="Talking points about health literacy."
          />
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
